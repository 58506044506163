
import comarcas from "@/core/data/comarcas";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Page from "@/layout/Page.vue";
import { AuthModule, FormulariosModule } from "@/store/modules";
import RecopilarDatosPN2DatosGenerales from "@/views/proyectosN2/RecopilarDatosPN2DetalleTabs/RecopilarDatosPN2DatosGenerales.vue";
import RecopilarDatosPN2Respuestas from "@/views/proyectosN2/RecopilarDatosPN2DetalleTabs/RecopilarDatosPN2Respuestas.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "proyectoN2-detalle",
  components: {
    Page,
    RecopilarDatosPN2DatosGenerales,
    RecopilarDatosPN2Respuestas,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const token = route.params.id as string; // SNA DeudaTécnica: Debería ser "id", porque no es un token!!
    const store = useStore();

    const module = getModule(FormulariosModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const solicitud = computed(() => module.solicitud);

    const organismo = computed(() => module.organismo);
    const respondido = computed(() => {
      if (module.organismo?.respondido) {
        return true;
      } else {
        return false;
      }
    });

    const cargaDetalle = ref<boolean>(false);
    const afterSync = ref<boolean>(false);

    onBeforeMount(async () => {
      cargaDetalle.value = true;
      if (token) {
        try {
          await module.fetchById({ id: token as string });

          if (module.errors) {
            Swal.fire({
              text: t("formularios.solicitud.cargar.proyectoinexistente"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        } catch (err) {
          Swal.fire({
            text: t("formularios.solicitud.cargar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          cargaDetalle.value = false;
        }
      }
    });

    watch(solicitud, () => {
      if (!solicitud.value) return;

      setCurrentPageBreadcrumbs([solicitud.value?.denominacion].join(" "), [
        t("menu.planCorresponsables"),
        { title: t("menu.recogidaDatos"), path: "/listados/datosProyectoN2" },
      ]);
    });

    return {
      //eliminar,
      isAdmin: !comarcaId,
      comarcas,
      token,
      respondido,
      afterSync,
      solicitud,
    };
  },
});
