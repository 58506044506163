import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecopilarDatosPN2DatosGenerales = _resolveComponent("RecopilarDatosPN2DatosGenerales")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_RecopilarDatosPN2Respuestas = _resolveComponent("RecopilarDatosPN2Respuestas")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { showBack: "" }, {
    actions: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_el_tabs, {
        type: "border-card",
        modelValue: "datosProyecto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            name: "datosProyecto",
            label: 
          _ctx.token
            ? _ctx.$t('formularios.proyectosN2.tabs.general')
            : _ctx.$t('formularios.solicitarDatos.tab')
        
          }, {
            default: _withCtx(() => [
              _createVNode(_component_RecopilarDatosPN2DatosGenerales)
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.token)
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 0,
                label: _ctx.$t('formularios.proyectosN2.tabs.respuestas'),
                lazy: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_RecopilarDatosPN2Respuestas)
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}